/* eslint-disable */
import PermissionChecker from '@/security/permission-checker';

export class NotificationPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.create = permissionChecker.match('createNotification');
  }
}
